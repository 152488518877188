@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
  --primary-white: #ffffff;
  --primary-white-50: rgba(255, 255, 255, 0.55);
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-navbar-blue: hsla(238, 88%, 16%, 0.78);
  --primary-navbar-purple: hsl(283, 39%, 53%, 0.92);
  --primary-grey: rgba(131, 131, 228, 0.02);
  --primary-purple: hsl(283, 39%, 53%);
  --primary-blue: rgb(52, 152, 219);
  --primary-black: #000000;
  --primary-green: rgb(15, 152, 15);
  --primary-hover-red: #f33501;
  --primary-slate-grey: #333;
  --primary-light-slate-grey: #737373;
  --primary-light: #f8f9fa;
  --primary-grey-transparent: rgba(71, 82, 93, 0.15);
}
body {
  font-family: "Raleway", sans-serif !important;
}

.site-section {
  padding: 8rem 0;
  border-bottom: 1px solid rgba(71, 82, 93, 0.15);
  border-bottom: 1px solid var(--primary-grey-transparent);
}

/*-------- MEDIA --------*/

@media (max-width: 768px) {
  .site-footer {
    padding: 4rem 0;
  }
}

/******************* NAVBAR *******************/

.navbar-brand {
  font-weight: 300 !important;
}

.navbar-dark .navbar-toggler {
  border: 0;
}

.navbar {
  border-bottom: 0.063rem solid #ffffff !important;
  border-bottom: 0.063rem solid var(--primary-white) !important;
  background-image: linear-gradient(to right, hsl(283, 39%, 53%, 0.92), hsla(238, 88%, 16%, 0.78), hsla(238, 88%, 16%, 0.78));
  background-image: linear-gradient(to right, var(--primary-navbar-purple), var(--primary-navbar-blue), var(--primary-navbar-blue));
  -webkit-backdrop-filter: blur(5px) !important;
          backdrop-filter: blur(5px) !important;
}

/*-------- MENU LINKS --------*/
nav a.nav-link {
  font-weight: 300;
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: rgba(255, 255, 255, 0.55) !important;
  color: var(--primary-white-50) !important;
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  font-weight: 400;
  color: #ffffff !important;
  color: var(--primary-white) !important;
}

.navbar-nav > li > a:hover {
  font-weight: 400;
  color: #ffffff !important;
  color: var(--primary-white) !important;
}

/******************* HEADER *******************/

.header-wrapper {
  position: relative;
  background: url(/static/media/pexels-mnm-zameer-330858.c4b69a7a.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.overlay {
  background-color: rgba(4, 7, 70, 0.3);
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.main-info {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: start;
  z-index: 1;
}

/******************* ABOUT ME *******************/

.line-about-me {
  position: relative;
  top: -0.75rem;
  width: 3rem;
  height: 0.125rem;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  display: inline-block;
}

.photo-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid hsl(283, 39%, 53%);
  border: 1px solid var(--primary-purple);
}

.about-heading {
  color: #666666;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
  font-weight: 300;
}

.about-title {
  font-style: normal;
  font-weight: normal;
  font-size: 1.7rem;
  line-height: 2.25rem;
}

.about-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

/*-------- MEDIA --------*/

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }

  .about-title {
    font-size: 1.5rem;
  }

  .photo-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

/******************* SKILLS *******************/

.skills {
  text-align: center;
  background: rgba(131, 131, 228, 0.02);
  background: var(--primary-grey);
}

.line-skills {
  position: relative;
  top: -0.75rem;
  width: 3rem;
  height: 0.125rem;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  display: inline-block;
}

.skills-heading {
  color: #666666;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
  font-weight: 300;
}

.skills .icon-wrapper {
  position: relative;
  margin: 0.375rem auto;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.skills-icon {
  font-size: 1.4rem;
  color: #ffffff;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.skills .box {
  height: auto;
  border-bottom: 0.3125rem solid hsl(283, 39%, 53%);
  border-bottom: 0.3125rem solid var(--primary-purple);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #ffffff;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.skills-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.6875rem;
  text-align: center;
}

.skills-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  text-align: center;
  list-style-type: none;
}

.skills-color-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.875rem;
  text-align: center;
  color: hsl(283, 39%, 53%);
  color: var(--primary-purple);
}

.skills .box:hover .skills-icon {
  color: hsl(283, 39%, 53%);
  color: var(--primary-purple);
}

.skills .box:hover .icon-wrapper {
  background: #ffffff;
  background: var(--primary-white);
  border: 1px solid hsl(283, 39%, 53%);
  border: 1px solid var(--primary-purple);
}

.frontend-skillset {
  width: auto;
  height: 100%;
  background: rgb(248, 249, 250);
  border-radius: 35px 0px 0px 35px;
}

.backend-skillset {
  width: auto;
  height: 100%;
  background: rgb(248, 249, 250);
  border-radius: 0px;
}
.design-skillset {
  width: auto;
  height: 100%;
  background: rgb(248, 249, 250);
  border-radius: 0px 35px 35px 0px;
}

/*-------- MEDIA --------*/

@media (max-width: 576px) {
  .frontend-skillset {
    border-radius: 35px 35px 0px 0px;
  }
  .design-skillset {
    border-radius: 0px 0px 35px 35px;
  }
}

/******************* PORTFOLIO *******************/

.portfolio-heading {
  color: #666666;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
  font-weight: 300;
}

.line-portfolio {
  position: relative;
  top: -0.75rem;
  width: 3rem;
  height: 0.125rem;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  display: inline-block;
}

.portfolio-image {
  width: 100%;
  padding: 0.5rem 0.5rem;
}
.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

/*-------- OVERFLOW --------*/

.overflow {
  position: absolute;
  opacity: 0;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  width: 100%;
  height: 100%;
  top: 0;
  border: 1px solid hsl(283, 39%, 53%);
  border: 1px solid var(--primary-purple);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -50%;
  margin-top: 32%;
  color: #ffffff;
  color: var(--primary-white);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 7rem;
}

/*-------- OVERFLOW BOX --------*/

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: hsl(283, 39%, 53%);
  color: var(--primary-purple);
}

.hyper-link:hover {
  color: var(--primary-red);
}

/*-------- MEDIA --------*/

@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
}

/******************* CONTACT ME *******************/

.contacts-heading {
  color: #666666;
  color: var(--primary-dark-grey);
  text-transform: uppercase;
  font-weight: 300;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

.line-contact {
  position: relative;
  top: -0.75rem;
  width: 3rem;
  height: 0.125rem;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  display: inline-block;
}

.form-group {
  padding: 3rem 1.5rem !important;
  background: #ffffff;
  background: var(--primary-white);
  border: 1px solid rgba(71, 82, 93, 0.15);
  border: 1px solid var(--primary-grey-transparent);
  border-radius: 10px;
}

/*-------- INPUT --------*/

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: #f8f9fa;
  background: var(--primary-light);
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid rgba(71, 82, 93, 0.15);
  border-bottom: 0.125rem solid var(--primary-grey-transparent);
  border-radius: 3px;
  color: hsl(283, 39%, 53%);
  color: var(--primary-purple);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
}

.contacts .container {
  padding: 2rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: #f8f9fa;
  background: var(--primary-light);
  color: hsl(283, 39%, 53%);
  color: var(--primary-purple);
  border-bottom: 0.125rem solid rgba(71, 82, 93, 0.15);
  border-bottom: 0.125rem solid var(--primary-grey-transparent);
}

.contacts textarea {
  height: 12.3rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.75rem;
  width: 0;
  height: 0.125rem;
  background: hsl(283, 39%, 53%);
  background: var(--primary-purple);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: #f33501;
  color: var(--primary-hover-red);
  font-size: small;
  text-align: left;
  font-weight: 600;
}

.success-message {
  font-weight: 600;
  color: #f9ab00;
  color: var(--primary-orange);
}

/*-------- CONTACT BUTTON --------*/

.btn-contact {
  border: 1px solid hsl(283, 39%, 53%);
  border: 1px solid var(--primary-purple);
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0.625rem;
  color: hsl(283, 39%, 53%);
  color: var(--primary-purple);
  margin: 2rem;
}

.btn-contact:hover {
  text-decoration: none;
  background-color: hsl(283, 39%, 53%);
  background-color: var(--primary-purple);
  color: #ffffff;
  color: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

/*-------- MEDIA --------*/

@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
    margin-bottom: 1.2rem;
  }
}

/******************* FOOTER *******************/

.site-footer {
  padding: 6rem 0;
  background: #333;
  background: var(--primary-slate-grey);
}

.site-footer p {
  color: #737373;
  color: var(--primary-light-slate-grey);
}

.footer-heading {
  font-size: 1rem;
  color: #ffffff;
  color: var(--primary-white);
}

.site-footer a {
  text-decoration: none;
  color: #737373;
  color: var(--primary-light-slate-grey);
}

.site-footer a:hover {
  text-decoration: none;
  color: #ffffff;
  color: var(--primary-white);
  cursor: pointer;
}

.footer-nav {
  margin-bottom: 0.625rem;
}

/*-------- MEDIA --------*/

@media (max-width: 768px) {
  .site-footer {
    padding: 3rem 0;
  }
}

